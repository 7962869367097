import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Get IBM Video Streaming public key`}</h2>
    <p>{`Get IBM Video Streaming public key to encyript the viewer authentication token`}</p>
    <pre><code parentName="pre" {...{}}>{`GET /viewer-auth-public-key.json
`}</code></pre>
    <p>{`Example request with CURL:`}</p>
    <pre><code parentName="pre" {...{}}>{`curl \\
        -X GET\\
        -H "Accept: application/json"\\
        "https://api.video.ibm.com/viewer-auth-public-key.json
`}</code></pre>
    <h3>{`Responses`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`200`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IBM Video Streaming’s public key`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Set viewer authentication parameters for a channel`}</h2>
    <p>{`Set viewer authentication content public key and auth URL to validate viewer authentication token signature or initaite the authentication`}</p>
    <pre><code parentName="pre" {...{}}>{`PUT /channels/{channelId}/viewer-auth.json
`}</code></pre>
    <p>{`Example request with CURL:`}</p>
    <pre><code parentName="pre" {...{}}>{`curl \\
        -X PUT\\
        -H "Accept: application/json"\\
        -H "Content-Type: application/x-www-form-urlencoded"\\
        -d "auth_url=https%3A%2F%2Fvideos.yourcompany.com%2Fcontent-auth-start&public_key=-----BEGIN+PUBLIC+KEY-----%0A+MIIBITANBgkqhkiG9w0BAQEFAAOCAQ4AMIIBCQKCAQB%2BHO2OHmtxzv%2BvD4ANvuNa%0A+hYu4lPdRdnJc0dcOEq4v8EH3JQhTkhhv3CaJgeWNdzEiMAvqazIMa7PCC%2F4STaUd%0A+DClojyq5qbVsy3ISEhltTWGwT7rkMZsw0QLduUeTPSjWC5RiRnsKIoX%2FPpRBWKZF%0A+CfY9g18QXsCuuPkX22RYggvI%2FOQM2XpOgY%2ByK1%2FLZjxLGLcShaKYh8k2tZ0FbCvJ%0A+uv3jg%2FdJ9xsz%2FIgo%2FjDlaC4N7%2FfeiQBZKls8kdk4wOthuanaOs1ZqmdlKOknSzOJ%0A+eH7DMZyURBtSVW%2FiGzOtLFxXfD%2BBDzGuLJvcouaXX%2BG5ugubjilwRvEiblnXU4Ib%0A+AgMBAAE%3D%0A+-----END+PUBLIC+KEY-----"
        "https://api.video.ibm.com/channels/{channelId}/viewer-auth.json
`}</code></pre>
    <h3>{`Parameters`}</h3>
    <p>{`Path parameters`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`channelId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Numeric channel ID`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Body parameters`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`auth_url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The URL where authentication flow should be initiate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`public_key`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The public key of your countent to validate viewer token signature in PEM format`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Example`}</p>
    <pre><code parentName="pre" {...{}}>{`auth_url=https%3A%2F%2Fvideos.yourcompany.com%2Fcontent-auth-start&public_key=-----BEGIN+PUBLIC+KEY-----%0A+MIIBITANBgkqhkiG9w0BAQEFAAOCAQ4AMIIBCQKCAQB%2BHO2OHmtxzv%2BvD4ANvuNa%0A+hYu4lPdRdnJc0dcOEq4v8EH3JQhTkhhv3CaJgeWNdzEiMAvqazIMa7PCC%2F4STaUd%0A+DClojyq5qbVsy3ISEhltTWGwT7rkMZsw0QLduUeTPSjWC5RiRnsKIoX%2FPpRBWKZF%0A+CfY9g18QXsCuuPkX22RYggvI%2FOQM2XpOgY%2ByK1%2FLZjxLGLcShaKYh8k2tZ0FbCvJ%0A+uv3jg%2FdJ9xsz%2FIgo%2FjDlaC4N7%2FfeiQBZKls8kdk4wOthuanaOs1ZqmdlKOknSzOJ%0A+eH7DMZyURBtSVW%2FiGzOtLFxXfD%2BBDzGuLJvcouaXX%2BG5ugubjilwRvEiblnXU4Ib%0A+AgMBAAE%3D%0A+-----END+PUBLIC+KEY-----
`}</code></pre>
    <h3>{`Responses`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`204`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Viewer authentication key set successfully`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bad request`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unauthorized`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Forbidden`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`500`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Server error`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Remove viewer authentication protection from your channel`}</h2>
    <pre><code parentName="pre" {...{}}>{`DELETE /channels/{channelId}/viewer-auth.json
`}</code></pre>
    <p>{`Example request with CURL:`}</p>
    <pre><code parentName="pre" {...{}}>{`curl \\
        -X DELETE\\
        -H "Accept: application/json"\\
        "https://api.video.ibm.com/channels/{channelId}/viewer-auth.json
`}</code></pre>
    <h3>{`Parameters`}</h3>
    <p>{`Path parameters`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`channelId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Numeric channel ID`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Responses`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`204`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Protection removed from the channel`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unauthorized`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Forbidden`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`500`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Server error`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Set Viewer Authentication for a video`}</h2>
    <p>{`Set Viewer Authentication content public key and auth URL to validate viewer authentication token signature or initaite the authentication`}</p>
    <pre><code parentName="pre" {...{}}>{`PUT /videos/{videoId}/viewer-auth.json
`}</code></pre>
    <p>{`Example request with CURL:`}</p>
    <pre><code parentName="pre" {...{}}>{`curl \\
        -X PUT\\
        -H "Accept: application/json"\\
        -H "Content-Type: application/x-www-form-urlencoded"\\
        -d "auth_url=https%3A%2F%2Fvideos.yourcompany.com%2Fcontent-auth-start&public_key=-----BEGIN+PUBLIC+KEY-----%0A+MIIBITANBgkqhkiG9w0BAQEFAAOCAQ4AMIIBCQKCAQB%2BHO2OHmtxzv%2BvD4ANvuNa%0A+hYu4lPdRdnJc0dcOEq4v8EH3JQhTkhhv3CaJgeWNdzEiMAvqazIMa7PCC%2F4STaUd%0A+DClojyq5qbVsy3ISEhltTWGwT7rkMZsw0QLduUeTPSjWC5RiRnsKIoX%2FPpRBWKZF%0A+CfY9g18QXsCuuPkX22RYggvI%2FOQM2XpOgY%2ByK1%2FLZjxLGLcShaKYh8k2tZ0FbCvJ%0A+uv3jg%2FdJ9xsz%2FIgo%2FjDlaC4N7%2FfeiQBZKls8kdk4wOthuanaOs1ZqmdlKOknSzOJ%0A+eH7DMZyURBtSVW%2FiGzOtLFxXfD%2BBDzGuLJvcouaXX%2BG5ugubjilwRvEiblnXU4Ib%0A+AgMBAAE%3D%0A+-----END+PUBLIC+KEY-----"
        "https://api.video.ibm.com/videos/{videoId}/viewer-auth.json
`}</code></pre>
    <h3>{`Parameters`}</h3>
    <p>{`Path parameters`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`videoId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Numeric video ID`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Body parameters`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`auth_url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The URL where authentication flow should be initiate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`public_key`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The public key of your countent to validate viewer token signature in PEM format`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Example`}</p>
    <pre><code parentName="pre" {...{}}>{`auth_url=https%3A%2F%2Fvideos.yourcompany.com%2Fcontent-auth-start&public_key=-----BEGIN+PUBLIC+KEY-----%0A+MIIBITANBgkqhkiG9w0BAQEFAAOCAQ4AMIIBCQKCAQB%2BHO2OHmtxzv%2BvD4ANvuNa%0A+hYu4lPdRdnJc0dcOEq4v8EH3JQhTkhhv3CaJgeWNdzEiMAvqazIMa7PCC%2F4STaUd%0A+DClojyq5qbVsy3ISEhltTWGwT7rkMZsw0QLduUeTPSjWC5RiRnsKIoX%2FPpRBWKZF%0A+CfY9g18QXsCuuPkX22RYggvI%2FOQM2XpOgY%2ByK1%2FLZjxLGLcShaKYh8k2tZ0FbCvJ%0A+uv3jg%2FdJ9xsz%2FIgo%2FjDlaC4N7%2FfeiQBZKls8kdk4wOthuanaOs1ZqmdlKOknSzOJ%0A+eH7DMZyURBtSVW%2FiGzOtLFxXfD%2BBDzGuLJvcouaXX%2BG5ugubjilwRvEiblnXU4Ib%0A+AgMBAAE%3D%0A+-----END+PUBLIC+KEY-----
`}</code></pre>
    <h3>{`Responses`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`204`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Viewer authentication key set successfully`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bad request`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unauthorized`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Forbidden`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`500`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Server error`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Remove viewer authentication protection from your video`}</h2>
    <pre><code parentName="pre" {...{}}>{`DELETE /videos/{videoId}/viewer-auth.json
`}</code></pre>
    <p>{`Example request with CURL:`}</p>
    <pre><code parentName="pre" {...{}}>{`curl \\
        -X DELETE\\
        -H "Accept: application/json"\\
        "https://api.video.ibm.com/videos/{videoId}/viewer-auth.json
`}</code></pre>
    <h3>{`Parameters`}</h3>
    <p>{`Path parameters`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`videoId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Numeric video ID`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Responses`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`204`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Protection removed from the channel`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unauthorized`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Forbidden`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`500`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Server error`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      